import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { copy, formatId } from '@recargas-dominicanas/core/utils';
import { AppToolbar } from '@recargas-dominicanas/core/components';
import { Divider } from '../../components/divider/divider.component';
import { InfoItem } from '../../components/info-item/info-item.component';
import { Info } from '../../components/info/info.component';
import { style } from './added-business.module.css';
export function AddedBusiness() {
    var history = useHistory();
    var location = useLocation();
    if (!location.state) {
        return React.createElement(Redirect, { to: '/' });
    }
    var _a = location.state, customer = _a.customer, business = _a.business, businessUser = _a.businessUser, token = _a.token;
    var link = "".concat(process.env.WEB_APP_DOMAIN, "/create-password/").concat(token);
    return (React.createElement("div", { className: style.container },
        React.createElement(AppToolbar, { icon: 'arrow_back', title: 'Cliente agregado', onClick: function () { return history.replace('/'); } }),
        React.createElement("div", { className: style.content },
            React.createElement(Info, { title: 'Informaci\u00F3n' },
                React.createElement(InfoItem, { title: 'Negocio', value: business.name }),
                React.createElement(InfoItem, { title: 'Propietario', value: customer.name }),
                React.createElement(InfoItem, { title: 'Id', value: formatId(business.id) }),
                React.createElement(InfoItem, { title: 'Usuario', value: businessUser.userName, color: 'red' })),
            React.createElement(Divider, null),
            React.createElement("div", { className: style.title }, "Enlace de activaci\u00F3n"),
            React.createElement("div", { className: style.link }, link),
            React.createElement("div", { className: style.copy, onClick: function () { return copy(link); } }, "Copiar enlace"))));
}
