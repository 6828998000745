import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { adminSlice } from './admin.slice';
import { businessSlice } from './business.slice';
import { depositsSlice } from './deposits.slice';
export var store = configureStore({
    reducer: {
        admin: adminSlice.reducer,
        business: businessSlice.reducer,
        deposits: depositsSlice.reducer
    }
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
