import React, { Fragment, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AppToolbar } from '@recargas-dominicanas/core/components';
import { style } from './deposit.module.css';
import { Image, OutlineButton } from '@recargas-dominicanas/core/components';
import { Divider } from '../../components/divider/divider.component';
import { formatCurrency, formatDate, formatId } from '@recargas-dominicanas/core/utils';
import { Info } from '../../components/info/info.component';
import { InfoItem } from '../../components/info-item/info-item.component';
import { AssignDepositModal } from '../../components/assign-deposit-modal/assign-deposit-modal.component';
import { getDeposit } from '../../store/deposits.slice';
export function Deposit() {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var deposit = getDeposit((_a = location.state) === null || _a === void 0 ? void 0 : _a.id);
    var _b = useState(false), openAssignModal = _b[0], setOpenAssignModal = _b[1];
    if (!deposit) {
        return React.createElement(Redirect, { to: '/deposits' });
    }
    return (React.createElement("div", { className: style.container },
        React.createElement(AppToolbar, { icon: 'arrow_back', title: 'Deposito', onClick: function () { return history.goBack(); } }),
        React.createElement("div", { className: style.content },
            React.createElement("div", { className: style.bankAccount },
                React.createElement(Image, { className: style.bankAccountImage, src: deposit.bankAccount.bank.image }),
                React.createElement("div", { className: style.bankAccountInfo },
                    React.createElement("div", { className: style.bankAccountName }, deposit.bankAccount.name),
                    React.createElement("div", { className: style.bankAccountNumber }, deposit.bankAccount.accountNumber))),
            React.createElement(Divider, null),
            React.createElement("div", { className: style.balanceTitle }, "Balance"),
            React.createElement("div", { className: style.balance }, formatCurrency(deposit.balance.amount)),
            React.createElement(Divider, null),
            React.createElement(Info, { title: 'Informaci\u00F3n' },
                React.createElement(InfoItem, { title: 'Fecha', value: formatDate(deposit.date) }),
                React.createElement(InfoItem, { title: 'Referencia', value: deposit.reference }),
                deposit.business &&
                    React.createElement(Fragment, null,
                        React.createElement(InfoItem, { title: 'Negocio', value: deposit.business.name }),
                        React.createElement(InfoItem, { title: 'Id', value: formatId(deposit.business.id) }))),
            !deposit.business &&
                React.createElement(OutlineButton, { style: style.button, text: 'Asignar', onClick: function () { return setOpenAssignModal(true); } })),
        React.createElement(AssignDepositModal, { open: openAssignModal, deposit: deposit, onClose: function () { return setOpenAssignModal(false); } })));
}
