// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-item-container-A2U4NN {
  --color: rgba(0, 0, 0, 80%);
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.nav-item-container-A2U4NN:hover {
  background-color: #00000008;
}

.nav-item-icon-vSTIgf {
  color: var(--color);
}

.nav-item-title-LLjU0K {
  flex-grow: 1;
  color: var(--color);
  font-size: 15px;
  font-weight: 500;
  margin-left: 24px;
}

.nav-item-arrowIcon-iozS2e {
  --size: 20px;
  color: var(--color);
}
`, "",{"version":3,"sources":["webpack://./src/components/nav-item/nav-item.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".container {\n  --color: rgba(0, 0, 0, 80%);\n  display: flex;\n  align-items: center;\n  padding: 12px 16px;\n}\n\n.container:hover {\n  background-color: #00000008;\n}\n\n.icon {\n  color: var(--color);\n}\n\n.title {\n  flex-grow: 1;\n  color: var(--color);\n  font-size: 15px;\n  font-weight: 500;\n  margin-left: 24px;\n}\n\n.arrowIcon {\n  --size: 20px;\n  color: var(--color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nav-item-container-A2U4NN`,
	"icon": `nav-item-icon-vSTIgf`,
	"title": `nav-item-title-LLjU0K`,
	"arrowIcon": `nav-item-arrowIcon-iozS2e`
};
export default ___CSS_LOADER_EXPORT___;
