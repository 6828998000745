import React from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { useHistory } from 'react-router-dom';
import { Divider } from '../divider/divider.component';
import { style } from './business-item.module.css';
export function BusinessItem(props) {
    var business = props.business;
    var history = useHistory();
    function handleClick() {
        history.push('/business', { id: business.id });
    }
    return (React.createElement("div", { className: style.container, onClick: handleClick },
        React.createElement("div", { className: style.item },
            React.createElement("div", { className: style.businessName }, business.name),
            React.createElement("div", { className: style.id }, business.id.toString().padStart(4, '0')),
            React.createElement("div", { className: style.ownerName }, business.customer.name),
            React.createElement("div", { className: style.balance }, formatCurrency(business.balance))),
        React.createElement(Divider, { className: style.divider })));
}
