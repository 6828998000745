import React from 'react';
import clsx from 'clsx';
import { mergeStyle } from './info-item.module.css';
export function InfoItem(props) {
    var title = props.title, value = props.value, _a = props.color, color = _a === void 0 ? 'black' : _a, customStyle = props.style;
    var style = mergeStyle(customStyle);
    if (!value)
        return null;
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.title }, title),
        React.createElement("div", { className: clsx(style.value, style[color]) }, value)));
}
