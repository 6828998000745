import React, { StrictMode } from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { App } from './pages/app/app.component';
import './style.css';
ReactDom.render(React.createElement(Provider, { store: store },
    React.createElement(StrictMode, null,
        React.createElement(App, null))), document.querySelector('#root'));
if (process.env.NODE_ENV === 'production') {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function () {
            navigator.serviceWorker.register('/service-worker.js');
        });
    }
}
