// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container-YWTX46 {
  padding: 40px 24px;
}

.login-header-tm7PH4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo-YJkCsr {
  width: 32px;
  height: 32px;
}

.login-title-qOuhwp {
  font-size: 18px;
  font-weight: 500;
  margin-left: 24px;
}

.login-card-ucDhaO {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  padding: 32px 24px;
  margin-top: 40px;
}

.login-cardIcon-FkN4p_ {
  --size: 48px;
}

.login-cardTitle_container-UpzLNm {
  margin-top: 16px;
}

.login-form-T85Crf {
  width: 100%;
  height: 200px;
}

.login-emailField_container-KF7fCf {
  width: 100%;
  min-height: 60px;
  margin-top: 44px;
}

.login-passwordField_container-FanI5W {
  width: 100%;
  margin-top: 16px;
}

.login-button_base-InH0Xu {
  width: 152px;
  margin-left: auto;
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".container {\n  padding: 40px 24px;\n}\n\n.header {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  width: 32px;\n  height: 32px;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 500;\n  margin-left: 24px;\n}\n\n.card {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border: 1px solid #ECECEC;\n  border-radius: 8px;\n  padding: 32px 24px;\n  margin-top: 40px;\n}\n\n.cardIcon {\n  --size: 48px;\n}\n\n.cardTitle_container {\n  margin-top: 16px;\n}\n\n.form {\n  width: 100%;\n  height: 200px;\n}\n\n.emailField_container {\n  width: 100%;\n  min-height: 60px;\n  margin-top: 44px;\n}\n\n.passwordField_container {\n  width: 100%;\n  margin-top: 16px;\n}\n\n.button_base {\n  width: 152px;\n  margin-left: auto;\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `login-container-YWTX46`,
	"header": `login-header-tm7PH4`,
	"logo": `login-logo-YJkCsr`,
	"title": `login-title-qOuhwp`,
	"card": `login-card-ucDhaO`,
	"cardIcon": `login-cardIcon-FkN4p_`,
	"cardTitle_container": `login-cardTitle_container-UpzLNm`,
	"form": `login-form-T85Crf`,
	"emailField_container": `login-emailField_container-KF7fCf`,
	"passwordField_container": `login-passwordField_container-FanI5W`,
	"button_base": `login-button_base-InH0Xu`
};
export default ___CSS_LOADER_EXPORT___;
