import { Icon } from '@recargas-dominicanas/core/components';
import React from 'react';
import { mergeStyle } from './nav-item.module.css';
export function NavItem(props) {
    var icon = props.icon, title = props.title, customStyle = props.style, onClick = props.onClick;
    var style = mergeStyle(customStyle);
    return (React.createElement("div", { className: style.container, onClick: onClick },
        React.createElement(Icon, { className: style.icon, icon: icon }),
        React.createElement("span", { className: style.title }, title),
        React.createElement(Icon, { className: style.arrowIcon, icon: 'arrow_forward' })));
}
