var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { formatCurrency, formatId, useForm } from '@recargas-dominicanas/core/utils';
import { BusinessApi, DepositApi } from '@recargas-dominicanas/core/api';
import { useUpdateDeposit } from '../../store/deposits.slice';
import { style } from './assign-deposit-modal.module.css';
import { Autocomplete, Button, Image, Modal, ModalActions, ModalContent, ModalToolbar, OutlineButton, SelectOption, Error } from '@recargas-dominicanas/core/components';
export function AssignDepositModal(props) {
    var _a = props.open, open = _a === void 0 ? true : _a, deposit = props.deposit, onClose = props.onClose;
    if (!open)
        return null;
    var form = useForm({ business: undefined });
    var _b = useState(), business = _b[0], setBusiness = _b[1];
    var _c = useState(false), showError = _c[0], setShowError = _c[1];
    var updateDeposit = useUpdateDeposit();
    function handleSearch(value) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = setBusiness;
                        return [4 /*yield*/, BusinessApi.search(value)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleClose() {
        form.clear();
        setShowError(false);
        onClose();
    }
    function handleAssign() {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!form.isValid())
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, DepositApi.assign(deposit.id, form.value.business.id)];
                    case 2:
                        _a.sent();
                        updateDeposit(__assign(__assign({}, deposit), { business: form.value.business }));
                        handleClose();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setShowError(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Modal, { open: true, style: style.modal, onClose: handleClose },
        React.createElement(ModalToolbar, { style: style.toolbar, title: 'Asignar deposito' }),
        React.createElement(ModalContent, null,
            React.createElement("div", { className: style.bankAccount },
                React.createElement(Image, { className: style.bankAccountImage, src: deposit.bankAccount.bank.image }),
                React.createElement("div", { className: style.bankAccountInfo },
                    React.createElement("div", { className: style.bankAccountName }, deposit.bankAccount.name),
                    React.createElement("div", { className: style.balance }, formatCurrency(deposit.balance.amount)),
                    React.createElement("div", { className: style.bankAccountNumber }, deposit.bankAccount.accountNumber))),
            React.createElement(Autocomplete, { style: style.autocomplete, placeholder: 'Seleccionar negocio', formField: form.fields.business, required: true, onSearch: handleSearch }, business === null || business === void 0 ? void 0 : business.map(function (business) { return (React.createElement(SelectOption, { key: business.id, value: business, label: business.name },
                React.createElement("div", { className: style.businessItem },
                    React.createElement("span", { className: style.businessName }, business.name),
                    React.createElement("span", { className: style.businessId }, formatId(business.id))))); })),
            React.createElement(Error, { style: style.error, show: showError, message: 'No se pudo asignar el deposito' })),
        React.createElement(ModalActions, null,
            React.createElement(Button, { text: 'Cancelar', onClick: handleClose }),
            React.createElement(OutlineButton, { text: 'Asignar', onClick: handleAssign }))));
}
