// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposits-container-G5q9oQ {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.deposits-content-ah_QCH {
  flex-grow: 1;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/deposits/deposits.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.content {\n  flex-grow: 1;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposits-container-G5q9oQ`,
	"content": `deposits-content-ah_QCH`
};
export default ___CSS_LOADER_EXPORT___;
