import React from 'react';
import { Image } from '@recargas-dominicanas/core/components';
import { style } from './deposit-item.module.css';
import { Divider } from '../divider/divider.component';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { useHistory } from 'react-router-dom';
export function DepositItem(props) {
    var deposit = props.deposit;
    var history = useHistory();
    function handleClick() {
        history.push('/deposit', { id: deposit.id });
    }
    return (React.createElement("div", { className: style.container, onClick: handleClick },
        React.createElement(Image, { className: style.image, src: deposit.bankAccount.bank.image }),
        React.createElement("div", { className: style.data },
            React.createElement("div", { className: style.name }, deposit.bankAccount.name),
            React.createElement("div", { className: style.balance }, formatCurrency(deposit.balance.amount)),
            React.createElement("div", { className: style.date }, formatDate(deposit.date)),
            deposit.business &&
                React.createElement("div", { className: style.asigned }, "Asignado")),
        React.createElement(Divider, { className: style.divider })));
}
