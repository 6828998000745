// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-container-VYw8Nh {
  padding: 28px 16px;
}

.info-title-_AcyUo {
  color: black;
  font-size: 16px;
  font-weight: 500;
  opacity: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/components/info/info.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".container {\n  padding: 28px 16px;\n}\n\n.title {\n  color: black;\n  font-size: 16px;\n  font-weight: 500;\n  opacity: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `info-container-VYw8Nh`,
	"title": `info-title-_AcyUo`
};
export default ___CSS_LOADER_EXPORT___;
