import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { AppToolbar } from '@recargas-dominicanas/core/components';
import { Divider } from '../../components/divider/divider.component';
import { InfoItem } from '../../components/info-item/info-item.component';
import { Info } from '../../components/info/info.component';
import { NavItem } from '../../components/nav-item/nav-item.component';
import { style } from './business.module.css';
import { formatCurrency, formatId, formatPhone } from '@recargas-dominicanas/core/utils';
import { getBusinessById } from '../../store/business.slice';
export function Business() {
    var _a;
    var history = useHistory();
    var location = useLocation();
    var business = getBusinessById((_a = location.state) === null || _a === void 0 ? void 0 : _a.id);
    if (!business) {
        return React.createElement(Redirect, { to: '/' });
    }
    return (React.createElement("div", { className: style.container },
        React.createElement(AppToolbar, { icon: 'arrow_back', title: business.name, onClick: function () { return history.goBack(); } }),
        React.createElement("div", { className: style.content },
            React.createElement(Info, { title: 'Ventas' },
                React.createElement(InfoItem, { title: 'Balance', value: formatCurrency(business.balance), color: 'green' }),
                React.createElement(InfoItem, { title: 'Ventas', value: formatCurrency(business.sales), color: 'green' }),
                React.createElement(InfoItem, { title: 'Beneficio', value: formatCurrency(business.profit), color: 'green' })),
            React.createElement(Divider, null),
            React.createElement("div", { className: style.navItems },
                React.createElement(NavItem, { icon: 'groups', title: 'Usuarios' }),
                React.createElement(NavItem, { icon: 'history', title: 'Transacciones' }),
                React.createElement(NavItem, { icon: 'payments', title: 'Depositos' })),
            React.createElement(Divider, null),
            React.createElement(Info, { title: 'Informacion' },
                React.createElement(InfoItem, { title: 'Id', value: formatId(business.id) }),
                React.createElement(InfoItem, { title: 'RNC', value: business.rnc }),
                React.createElement(InfoItem, { title: 'Tel\u00E9fono', value: formatPhone(business.phone) }),
                React.createElement(InfoItem, { title: 'Email', value: business.email }),
                React.createElement(InfoItem, { title: 'Ciudad', value: business.city }),
                React.createElement(InfoItem, { title: 'Direccion', value: business.address }),
                React.createElement(InfoItem, { title: 'Porcentaje', value: "".concat(business.percent, "%") })),
            React.createElement(Divider, null),
            React.createElement(Info, { title: 'Propietario' },
                React.createElement(InfoItem, { title: 'Nombre', value: business.customer.name }),
                React.createElement(InfoItem, { title: 'C\u00E9dula', value: business.customer.docNumber }),
                React.createElement(InfoItem, { title: 'Tel\u00E9fono', value: formatPhone(business.customer.phone) }),
                React.createElement(InfoItem, { title: 'Email', value: business.customer.email })))));
}
