// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-deposit-modal-modal_card-FfG_0q {
  width: calc(100% - 32px);
  max-width: 340px;
  min-height: 320px;
}

.assign-deposit-modal-toolbar_container-CVqwj9 {
  border-bottom: none;
}

.assign-deposit-modal-bankAccount-o70Qw_ {
  display: flex;
  margin-top: 16px;
}

.assign-deposit-modal-bankAccountImage-DrPQcA {
  width: 36px;
  height: 36px;
}

.assign-deposit-modal-bankAccountInfo-dkEcc1 {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-content: space-between;
  margin-left: 24px;
}

.assign-deposit-modal-bankAccountName-zWPQb9 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 90%);
}

.assign-deposit-modal-bankAccountNumber-QC38EX {
  font-size: 16px;
  color: rgba(0, 0, 0, 90%);
  margin-top: 12px;
}

.assign-deposit-modal-balance-qlPqRS {
  font-size: 15px;
  font-weight: 500;
  color: var(--money-color);
}

.assign-deposit-modal-autocomplete_container-tgc1cR {
  width: 100%;
  margin-top: 32px;
}

.assign-deposit-modal-autocomplete_popup-WzygD0 {
  max-height: 180px;
  overflow: auto;
}

.assign-deposit-modal-businessItem-hSZX3V {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.assign-deposit-modal-businessName-mhHr40 {
  font-size: 16px;
}

.assign-deposit-modal-businessId-i6XPWU {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 80%);
}

.assign-deposit-modal-error_container-iw_9sz {
  margin: 24px 0px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/assign-deposit-modal/assign-deposit-modal.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,6BAA6B;EAC7B,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".modal_card {\n  width: calc(100% - 32px);\n  max-width: 340px;\n  min-height: 320px;\n}\n\n.toolbar_container {\n  border-bottom: none;\n}\n\n.bankAccount {\n  display: flex;\n  margin-top: 16px;\n}\n\n.bankAccountImage {\n  width: 36px;\n  height: 36px;\n}\n\n.bankAccountInfo {\n  flex-grow: 1;\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-template-rows: auto auto;\n  justify-content: space-between;\n  margin-left: 24px;\n}\n\n.bankAccountName {\n  font-size: 16px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 90%);\n}\n\n.bankAccountNumber {\n  font-size: 16px;\n  color: rgba(0, 0, 0, 90%);\n  margin-top: 12px;\n}\n\n.balance {\n  font-size: 15px;\n  font-weight: 500;\n  color: var(--money-color);\n}\n\n.autocomplete_container {\n  width: 100%;\n  margin-top: 32px;\n}\n\n.autocomplete_popup {\n  max-height: 180px;\n  overflow: auto;\n}\n\n.businessItem {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px;\n}\n\n.businessName {\n  font-size: 16px;\n}\n\n.businessId {\n  font-size: 15px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 80%);\n}\n\n.error_container {\n  margin: 24px 0px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `assign-deposit-modal-modal_card-FfG_0q`,
	"toolbar_container": `assign-deposit-modal-toolbar_container-CVqwj9`,
	"bankAccount": `assign-deposit-modal-bankAccount-o70Qw_`,
	"bankAccountImage": `assign-deposit-modal-bankAccountImage-DrPQcA`,
	"bankAccountInfo": `assign-deposit-modal-bankAccountInfo-dkEcc1`,
	"bankAccountName": `assign-deposit-modal-bankAccountName-zWPQb9`,
	"bankAccountNumber": `assign-deposit-modal-bankAccountNumber-QC38EX`,
	"balance": `assign-deposit-modal-balance-qlPqRS`,
	"autocomplete_container": `assign-deposit-modal-autocomplete_container-tgc1cR`,
	"autocomplete_popup": `assign-deposit-modal-autocomplete_popup-WzygD0`,
	"businessItem": `assign-deposit-modal-businessItem-hSZX3V`,
	"businessName": `assign-deposit-modal-businessName-mhHr40`,
	"businessId": `assign-deposit-modal-businessId-i6XPWU`,
	"error_container": `assign-deposit-modal-error_container-iw_9sz`
};
export default ___CSS_LOADER_EXPORT___;
