// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-item-container-P5TksS {
  display: flex;
  position: relative;
  padding: 20px 16px;
}

.deposit-item-container-P5TksS:active {
  background: rgba(0, 0, 0, 4%)
}

.deposit-item-container-P5TksS:last-child .deposit-item-divider-NvDYaM {
  display: none;
}

.deposit-item-image-Rfo_i_ {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.deposit-item-data-g_9GDG {
  flex-grow: 1;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-content: space-between;
  margin-left: 24px;
}

.deposit-item-name-Eg_9YK {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 80%);
}

.deposit-item-balance-lyt_MT {
  justify-self: end;
  color: var(--money-color);
  font-size: 15px;
  font-weight: 500;
  opacity: 80%;
}

.deposit-item-date-bFdKXm {
  font-size: 15px;
  margin-top: 20px;
  color: rgba(0, 0, 0, 80%);
}

.deposit-item-asigned-z0adIN {
  justify-self: end;
  align-self: flex-end;
  color: var(--accent-color);
  font-size: 14px;
  font-weight: 500;
  opacity: 80%;
  margin-top: 20px;
}

.deposit-item-divider-NvDYaM {
  position: absolute;
  bottom: 0px;
  left: 56px;
  right: 0px;
  margin-left: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/deposit-item/deposit-item.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,6BAA6B;EAC7B,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n  padding: 20px 16px;\n}\n\n.container:active {\n  background: rgba(0, 0, 0, 4%)\n}\n\n.container:last-child .divider {\n  display: none;\n}\n\n.image {\n  width: 40px;\n  height: 40px;\n  flex-shrink: 0;\n}\n\n.data {\n  flex-grow: 1;\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-template-rows: auto auto;\n  justify-content: space-between;\n  margin-left: 24px;\n}\n\n.name {\n  font-size: 16px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 80%);\n}\n\n.balance {\n  justify-self: end;\n  color: var(--money-color);\n  font-size: 15px;\n  font-weight: 500;\n  opacity: 80%;\n}\n\n.date {\n  font-size: 15px;\n  margin-top: 20px;\n  color: rgba(0, 0, 0, 80%);\n}\n\n.asigned {\n  justify-self: end;\n  align-self: flex-end;\n  color: var(--accent-color);\n  font-size: 14px;\n  font-weight: 500;\n  opacity: 80%;\n  margin-top: 20px;\n}\n\n.divider {\n  position: absolute;\n  bottom: 0px;\n  left: 56px;\n  right: 0px;\n  margin-left: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposit-item-container-P5TksS`,
	"divider": `deposit-item-divider-NvDYaM`,
	"image": `deposit-item-image-Rfo_i_`,
	"data": `deposit-item-data-g_9GDG`,
	"name": `deposit-item-name-Eg_9YK`,
	"balance": `deposit-item-balance-lyt_MT`,
	"date": `deposit-item-date-bFdKXm`,
	"asigned": `deposit-item-asigned-z0adIN`
};
export default ___CSS_LOADER_EXPORT___;
