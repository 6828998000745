// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-container-mc67z9 {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.deposit-content-qkwzpq {
  flex-grow: 1;
  overflow: auto;
}

.deposit-bankAccount-BM1lF0 {
  display: flex;
  padding: 28px 16px;
}

.deposit-bankAccountImage-yMsxGN {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
}

.deposit-bankAccountInfo-cNejVR {
  flex-grow: 1;
  margin-left: 24px;
}

.deposit-bankAccountName-g2Du0u {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 90%);
}

.deposit-bankAccountNumber-MIj5zN {
  font-size: 16px;
  color: rgba(0, 0, 0, 90%);
  margin-top: 12px;
}

.deposit-balanceTitle-zpec4n {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 90%);
  margin: 28px 16px 0px;
}

.deposit-balance-nKZpMs {
  color: var(--money-color);
  font-size: 24px;
  font-weight: bold;
  margin: 20px 16px 28px;
}

.deposit-button_base-yzi_db {
  width: calc(100% - 32px);
  height: 40px;
  margin: 20px 16px 32px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/deposit/deposit.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.content {\n  flex-grow: 1;\n  overflow: auto;\n}\n\n.bankAccount {\n  display: flex;\n  padding: 28px 16px;\n}\n\n.bankAccountImage {\n  width: 52px;\n  height: 52px;\n  flex-shrink: 0;\n}\n\n.bankAccountInfo {\n  flex-grow: 1;\n  margin-left: 24px;\n}\n\n.bankAccountName {\n  font-size: 18px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 90%);\n}\n\n.bankAccountNumber {\n  font-size: 16px;\n  color: rgba(0, 0, 0, 90%);\n  margin-top: 12px;\n}\n\n.balanceTitle {\n  font-size: 16px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 90%);\n  margin: 28px 16px 0px;\n}\n\n.balance {\n  color: var(--money-color);\n  font-size: 24px;\n  font-weight: bold;\n  margin: 20px 16px 28px;\n}\n\n.button_base {\n  width: calc(100% - 32px);\n  height: 40px;\n  margin: 20px 16px 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deposit-container-mc67z9`,
	"content": `deposit-content-qkwzpq`,
	"bankAccount": `deposit-bankAccount-BM1lF0`,
	"bankAccountImage": `deposit-bankAccountImage-yMsxGN`,
	"bankAccountInfo": `deposit-bankAccountInfo-cNejVR`,
	"bankAccountName": `deposit-bankAccountName-g2Du0u`,
	"bankAccountNumber": `deposit-bankAccountNumber-MIj5zN`,
	"balanceTitle": `deposit-balanceTitle-zpec4n`,
	"balance": `deposit-balance-nKZpMs`,
	"button_base": `deposit-button_base-yzi_db`
};
export default ___CSS_LOADER_EXPORT___;
