import React from 'react';
import { getAdmin } from '../../store/admin.slice';
import { style } from './drawer.module.css';
import { Drawer as BaseDrawer, DrawerHeader, DrawerItem } from '@recargas-dominicanas/core/components';
export function Drawer(props) {
    var open = props.open, onClose = props.onClose;
    var admin = getAdmin();
    return (React.createElement(BaseDrawer, { open: open, onClose: onClose },
        React.createElement(DrawerHeader, { className: style.drawerHeader },
            React.createElement("img", { className: style.drawerImage, src: 'recargas-dominicanas-32x32.svg' }),
            React.createElement("div", { className: style.drawerTitle }, "Recargas Dominicanas"),
            React.createElement("div", { className: style.drawerName }, admin.name)),
        React.createElement(DrawerItem, { icon: 'groups', title: 'Clientes', route: '/', align: 'top' }),
        React.createElement(DrawerItem, { icon: 'account_balance', title: 'Depositos', route: '/deposits', align: 'top' })));
}
