// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider-container-NEWs8R {
  height: 1px;
  background-color: var(--border-color);
  margin: 0px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/divider/divider.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qCAAqC;EACrC,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 1px;\n  background-color: var(--border-color);\n  margin: 0px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `divider-container-NEWs8R`
};
export default ___CSS_LOADER_EXPORT___;
