// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-item-container-PHg6b_ {
  height: 96px;
  position: relative;
}

.business-item-container-PHg6b_:hover {
  background-color: #00000008;
}

.business-item-container-PHg6b_:last-child .business-item-divider-8QKnbx {
  display: none;
}

.business-item-item-p9MhSD {
  height: 100%;
  display: grid;
  align-content: space-between;
  justify-content: space-between;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding: 20px 16px;
}

.business-item-divider-8QKnbx {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.business-item-businessName-J0VRmt {
  font-size: 16px;
  font-weight: 500;
  opacity: 90%;
}

.business-item-id-ctUz_U {
  text-align: end;
}

.business-item-balance-RsgT_2 {
  align-self: center;
  color: var(--money-color);
  font-size: 15px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/business-item/business-item.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,4BAA4B;EAC5B,8BAA8B;EAC9B,gCAAgC;EAChC,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 96px;\n  position: relative;\n}\n\n.container:hover {\n  background-color: #00000008;\n}\n\n.container:last-child .divider {\n  display: none;\n}\n\n.item {\n  height: 100%;\n  display: grid;\n  align-content: space-between;\n  justify-content: space-between;\n  grid-template-columns: auto auto;\n  grid-template-rows: auto auto;\n  padding: 20px 16px;\n}\n\n.divider {\n  position: absolute;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n}\n\n.businessName {\n  font-size: 16px;\n  font-weight: 500;\n  opacity: 90%;\n}\n\n.id {\n  text-align: end;\n}\n\n.balance {\n  align-self: center;\n  color: var(--money-color);\n  font-size: 15px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `business-item-container-PHg6b_`,
	"divider": `business-item-divider-8QKnbx`,
	"item": `business-item-item-p9MhSD`,
	"businessName": `business-item-businessName-J0VRmt`,
	"id": `business-item-id-ctUz_U`,
	"balance": `business-item-balance-RsgT_2`
};
export default ___CSS_LOADER_EXPORT___;
