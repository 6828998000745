// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-deposit-container-cPKK2Y {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.add-deposit-content-I1qO0d {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 12px 14px 12px 24px;
}

.add-deposit-form-hohJtq {
  flex-grow: 1;
}

.add-deposit-select_container-aCUhz1,
.add-deposit-textField_container-nLDs9I,
.add-deposit-autocomplete_container-zOxZ0h {
  width: 100%;
  margin-top: 24px;
}

.add-deposit-autocomplete_content-TizJH8 {
  height: 44px;
}

.add-deposit-textField_input-tsp6ua,
.add-deposit-autocomplete_input-XfVDaU,
.add-deposit-select_input-Cx778X {
  height: 44px;
}

.add-deposit-autocomplete_input-XfVDaU {
  background-color: transparent;
}

.add-deposit-autocomplete_popup-snQtEW {
  max-height: 180px;
  overflow: auto;
}

.add-deposit-selectItem-yQWX7n {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.add-deposit-businessName-fFLMo0 {
  font-size: 16px;
}

.add-deposit-businessId-zeNxod {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 80%);
}

.add-deposit-balance-dAjjxD {
  font-size: 15px;
  font-weight: 500;
  color: var(--money-color);
}

.add-deposit-error_container-VlpX7M {
  margin: 32px 0px 24px;
}

.add-deposit-button_base-WzAxdH {
  width: 136px;
  height: 36px;
  flex-shrink: 0;
  margin: 32px 0px 12px auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/add-deposit/add-deposit.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;;;EAGE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;;;EAGE,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.content {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  overflow: scroll;\n  padding: 12px 14px 12px 24px;\n}\n\n.form {\n  flex-grow: 1;\n}\n\n.select_container,\n.textField_container,\n.autocomplete_container {\n  width: 100%;\n  margin-top: 24px;\n}\n\n.autocomplete_content {\n  height: 44px;\n}\n\n.textField_input,\n.autocomplete_input,\n.select_input {\n  height: 44px;\n}\n\n.autocomplete_input {\n  background-color: transparent;\n}\n\n.autocomplete_popup {\n  max-height: 180px;\n  overflow: auto;\n}\n\n.selectItem {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px;\n}\n\n.businessName {\n  font-size: 16px;\n}\n\n.businessId {\n  font-size: 15px;\n  font-weight: 500;\n  color: rgba(0, 0, 0, 80%);\n}\n\n.balance {\n  font-size: 15px;\n  font-weight: 500;\n  color: var(--money-color);\n}\n\n.error_container {\n  margin: 32px 0px 24px;\n}\n\n.button_base {\n  width: 136px;\n  height: 36px;\n  flex-shrink: 0;\n  margin: 32px 0px 12px auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `add-deposit-container-cPKK2Y`,
	"content": `add-deposit-content-I1qO0d`,
	"form": `add-deposit-form-hohJtq`,
	"select_container": `add-deposit-select_container-aCUhz1`,
	"textField_container": `add-deposit-textField_container-nLDs9I`,
	"autocomplete_container": `add-deposit-autocomplete_container-zOxZ0h`,
	"autocomplete_content": `add-deposit-autocomplete_content-TizJH8`,
	"textField_input": `add-deposit-textField_input-tsp6ua`,
	"autocomplete_input": `add-deposit-autocomplete_input-XfVDaU`,
	"select_input": `add-deposit-select_input-Cx778X`,
	"autocomplete_popup": `add-deposit-autocomplete_popup-snQtEW`,
	"selectItem": `add-deposit-selectItem-yQWX7n`,
	"businessName": `add-deposit-businessName-fFLMo0`,
	"businessId": `add-deposit-businessId-zeNxod`,
	"balance": `add-deposit-balance-dAjjxD`,
	"error_container": `add-deposit-error_container-VlpX7M`,
	"button_base": `add-deposit-button_base-WzAxdH`
};
export default ___CSS_LOADER_EXPORT___;
